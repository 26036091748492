/*global WebFont */

const app = {};

const DSSonata_PopUpFormCallbacks = {
  personStandartHourRate: {
    close: function (popup, $node) {
      let el = $('[data-popup-form-callback]');
      let url = el.data('url-hour-rate');
      let selector = $('[data-hour-rate]');

      let xhrRequest = null;

      if (xhrRequest != null && xhrRequest.readyState != 4) {
        xhrRequest.abort();
      }
      xhrRequest = $.ajax({
        url: url,
        method: 'get',
      }).done(function (data) {
        selector.html(data);
      });
    }
  },
  jobInterviewParticipantReaction: {
    success: function ($form, json, popup, $node) {
      var reactionName = $form.find('select[data-type=reaction] option:selected').text();
      $node.closest('[data-type=participant]').find('[data-type=reaction]').text(reactionName);
    }
  },
  paymentRefundList: {
    success: function ($form, json, popup, $node) {
      var url = Routing.generate('admin_app_refund_inline-row', {'id': json.objectId});
      $.get(url, function(data) {
        var $tableBody = $("#refundsTable tbody");
        var $row = $tableBody.find("tr[data-type-refund-id="+json.objectId+"]");
        if ($row.length > 0) {
          $row.replaceWith(data);
        } else {
          $tableBody.append(data);
        }
        showPaymentWarningBox('#refunds');
      });
    }
  },
  paymentPenaltyList: {
    success: function ($form, json, popup, $node) {
      var url = Routing.generate('admin_app_penalty_inline-row', {'id': json.objectId});
      $.get(url, function(data) {
        var $tableBody = $("#penaltiesTable tbody");
        var $row = $tableBody.find("tr[data-type-penalty-id="+json.objectId+"]");
        if ($row.length > 0) {
          $row.replaceWith(data);
        } else {
          $tableBody.append(data);
        }
        showPaymentWarningBox('#penalties');
      });
    }
  },
  paymentAdvancePaymentsList: {
    success: function ($form, json, popup, $node) {
      var url = Routing.generate('admin_app_advancepayment_inline-row', {'id': json.objectId});
      $.get(url, function(data) {
        var $tableBody = $("#advancePaymentsTable tbody");
        var $row = $tableBody.find("tr[data-type-advancePayment-id="+json.objectId+"]");
        if ($row.length > 0) {
          $row.replaceWith(data);
        } else {
          $tableBody.append(data);
        }
        showPaymentWarningBox('#advancePayments');
      });
    }
  },
  paymentDepositoryRecordsList: {
    success: function ($form, json, popup, $node) {
      var url = Routing.generate('admin_app_depositoryrecord_inline-row', {'id': json.objectId});
      $.get(url, function(data) {
        var $tableBody = $("#depositoryRecordsTable tbody");
        var $row = $tableBody.find("tr[data-type-depositoryRecord-id="+json.objectId+"]");
        if ($row.length > 0) {
          $row.replaceWith(data);
        } else {
          $tableBody.append(data);
        }
        showPaymentWarningBox('#depositoryRecords');
      });
    }
  },
  paymentRefundedCostsList: {
    success: function ($form, json, popup, $node) {
      var url = Routing.generate('admin_app_refundedcost_inline-row', {'id': json.objectId});
      $.get(url, function(data) {
        var $tableBody = $("#refundedCostsTable tbody");
        var $row = $tableBody.find("tr[data-type-refundedCost-id="+json.objectId+"]");
        if ($row.length > 0) {
          $row.replaceWith(data);
        } else {
          $tableBody.append(data);
        }
        showPaymentWarningBox('#refundedCosts');
      });
    }
  },
  paymentCostsPayment: {
    success: function ($form, json, popup, $node) {
      var url = Routing.generate('admin_app_cost_inline-row', {'id': json.objectId});
      $.get(url, function(data) {
        var $tableBody = $("#costsCurrentPaymentTable tbody");
        var $row = $tableBody.find("tr[data-type-cost-id="+json.objectId+"]");
        if ($row.length > 0) {
          $row.replaceWith(data);
        } else {
          $tableBody.append(data);
        }
        showPaymentWarningBox('#costsCurrentPayment');
      });
    }
  },
  issuePaymentInvoice: {
    success: function ($form, json, popup, $node) {
      window.location.href = Routing.generate('admin_app_payment_invoice_pdf', {'id': json.objectId});
      replacePaymentContent();
    }
  },
  jobOrderToolsRequest: {
    close: function () {
      location.reload(true);
    }
  }
};

WebFont.load({
  custom: {
    families: ['raleway'],
  },
});

$(document).foundation();

$(function () {
  createVisibilitySwitcher();
  initializationDatepicker();
  initColorpicker();
});

function initializationDatepicker(cSelector = "[data-control='datepicker']") {
  $(cSelector).each(function () {
    $(this).fdatepicker({
      language: 'cs',
      format: getFormat($(this)),
      formatText: getFormat($(this)),
      disableDblClickSelection: true,
    });
  });

  function getFormat($element) {
    return $element.data('date-format') ? $element.data('date-format') : 'dd.mm.yyyy';
  }
}

function highlightRowByAnchor() {
  let $row = $(window.location.hash);
  if ($row.length == 1) {
    $row.addClass('highlight-row');
  }
}

function retagSelect(eSelect = '.selectWorkers') {
  const participantSelect = document.querySelectorAll(eSelect);
  const arraySelected = [];
  if (participantSelect.length === 1) {
    const cHorizontalRule = document.querySelector('.horizontalRule');
    if (cHorizontalRule) {
      cHorizontalRule.classList.add('hide');
    }
  }

  for (const participant of participantSelect) {
    const participantSelected = participant.querySelector('option[selected="selected"]');
    const participantFakedValue = participant.getAttribute('value');
    if (participantSelected) {
      arraySelected.push(participantSelected.getAttribute('value'));
    } else if (participantFakedValue) {
      arraySelected.push(participantFakedValue);
    }
  }

  for (const participant of participantSelect) {
    const options = participant.querySelectorAll('option');
    for (const option of options) {
      option.removeAttribute('disabled');
      arraySelected.forEach((i) => {
        if (option.getAttribute('value') === i) {
          if (!option.getAttribute('selected')) {
            option.setAttribute('disabled', 'disabled');
          }
        }
      });
    }
  }
}

function initColorpicker() {
  $('[data-color-picker]').colorpicker({
    format: 'hex',
    template:
    '<div class="colorpicker colorpicker-dropdown-menu">' +
    '<div class="colorpicker-saturation"><i><b></b></i></div>' +
    '<div class="colorpicker-hue"><i></i></div>' +
    '<div class="colorpicker-color"><div /></div>' +
    '<div class="colorpicker-selectors"></div>' +
    '</div>',
  });
}

function setWindowUrl(url) {
  if (typeof window.history.pushState == 'function') {
    window.history.pushState(url, window.title, url);
  } else {
    window.location.href = url;
  }
}

function chooseColor() {
  $('[data-color-pick]').on('click', function () {
    let dataColor = $(this).children().css('backgroundColor');
    $('[data-color-picker]').colorpicker('setValue', dataColor);
  });
}
