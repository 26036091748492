$.extend(app, {
  workers: null,
});

app.workers = (function () {
  const workersCollection = createWorkersCollection();
  const worksCollectionInitializer = createWorksCollectionInitializer();

  return {
    config: {
      workersCollection: workersCollection.config,
      worksCollection: worksCollectionInitializer.config.worksCollection,
    },
    init: function () {
      workersCollection.init();
    },
  };

  function createWorkersCollection() {
    const config = {
      prototypeName: '',
      selector: {
        button: {
          addition: '',
        },
        container: '',
        people: '',
        worker: '',
      },
    };
    let lastWorkerIndex;

    return {
      config: config,
      init: function () {
        let $workers = $(config.selector.container).find(config.selector.worker);

        $workers.each((i, worker) => {
          initWorker($(worker));
        });
        $(config.selector.button.addition).on('click', addWorker);

        initializationDatepicker();
        disableSelectedPeople();

        lastWorkerIndex = $workers.length - 1;
        if ($workers.length > 0) {
          showHorizontalRule();
        }
      },
    };

    function initWorker($worker) {
      worksCollectionInitializer.initWorksCollection($worker);
      $worker.find(config.selector.people).on('change', function () {
        disableSelectedPeople();
      });

      $worker.on('lastWorkRemoved', function () {
        $(this).remove();
        if ($(config.selector.container).find(config.selector.worker).length < 1) {
          hideHorizontalRule();
        }
      });
    }

    function addWorker() {
      const $container = $(config.selector.container);
      const $worker = createWorker($container);

      initWorker($worker);
      $container.append($worker);
      initializationDatepicker();
      disableSelectedPeople();
      showHorizontalRule();

      function createWorker($container) {
        lastWorkerIndex++;
        return $(
          decodeURIComponent($container.data('prototype')).replace(
            new RegExp(config.prototypeName, 'g'),
            lastWorkerIndex.toString()
          )
        );
      }
    }

    function showHorizontalRule() {
      $('.horizontalRule').removeClass('hide');
    }

    function hideHorizontalRule() {
      $('.horizontalRule').addClass('hide');
    }

    function disableSelectedPeople() {
      const $selects = $(config.selector.people);
      const selected = findSelected();

      $selects.each(function () {
        const $select = $(this);
        $select.find('option').each(function () {
          $(this).removeAttr('disabled');
          if (($(this).val() !== $select.val()) &&
              (selected.indexOf(String($(this).val())) !== -1)
          ) {
            $(this).attr('disabled', 'disabled');
          }
        });
      });

      function findSelected() {
        let selected = [];

        $selects.each(function () {
          selected.push($(this).val());
        });

        return selected;
      }
    }
  }

  function createWorksCollectionInitializer() {
    const config = {
      worksCollection: {
        prototypeName: '',
        selector: {
          container: '',
          context: '',
          work: '',
          button: {
            addition: '',
            removing: '',
          },
          date: {
            end: '',
            start: '',
          },
        },
      },
    };

    return {
      config: config,
      initWorksCollection: function ($worker) {
        initWorksCollection(config.worksCollection, $worker);
      },
    };

    function initWorksCollection(config, $worker) {
      const $works = $worker.find(config.selector.work);
      let lastWorkIndex = $works.length - 1;

      $works.each((i, work) => {
        bindRemoving($(work).find(config.selector.button.removing));
      });
      bindAddition($worker.find(config.selector.button.addition));

      function bindRemoving($button) {
        $button.on('click', function (event) {
          let $button = $(event.target);
          $button.parents(config.selector.work).remove();
          if ($worker.find(config.selector.work).length < 1) {
            $worker.trigger('lastWorkRemoved');
          }
        });
      }

      function bindAddition($button) {
        $button.on('click', function (event) {
          const $container = $(event.target)
            .parents(config.selector.context)
            .find(config.selector.container);
          const $work = createWork($container.data('prototype'));

          bindRemoving($work.find(config.selector.button.removing));

          $container.append($work);
          initializationDatepicker();
        });
      }

      function createWork(template) {
        lastWorkIndex++;
        return $(
          decodeURIComponent(template).replace(
            new RegExp(config.prototypeName, 'g'),
            lastWorkIndex.toString()
          )
        );
      }
    }
  }
})();
