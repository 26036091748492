$(function() {
  downloadPayment($('[data-button-payment]'));
});

function showPaymentWarningBox(element) {
  const selector = $(element);
  const table = selector.find($('table'));
  const paymentWarning = selector.find($('.paymentWarning'));
  const tableRowsLength = table.find($('tbody tr')).length;

  if(tableRowsLength <= 0) {
    table.addClass('hide');
    paymentWarning.removeClass('hide');
  }
  else {
    table.removeClass('hide');
    paymentWarning.addClass('hide');
  }
}
function deletePaymentItem(url, objectId, type) {
  $.ajax({url: url, type: 'DELETE',
    success: function (data) {
      if (data.result == 'ok') {
          $("table tbody").find("tr[data-type-"+type+"-id="+objectId+"]").remove();
      }
      showPaymentWarningBox('#refunds');
      showPaymentWarningBox('#penalties');
      showPaymentWarningBox('#advancePayments');
      showPaymentWarningBox('#costsCurrentPayment');
      showPaymentWarningBox('#costsFuturePayment');
      showPaymentWarningBox('#depositoryRecords');
    }
  });
}

function downloadPayment(selector) {
  selector.click(function(e) {
    e.preventDefault();
    var href = $(this).attr('href');
    window.open(href, '_blank');
    $('#middleRightWrapper').addClass('isLoading');
    window.location.reload(true);
    return false;
  });
}

function replacePaymentContent() {
  const $wrapperElement = $('#middleRightWrapper');
  let xhrRequest = null;
  if (xhrRequest != null && xhrRequest.readyState != 4) {
    xhrRequest.abort();
  }

  $wrapperElement.addClass('isLoading');
  xhrRequest = $.ajax({
    url: window.location.href,
    method: 'get'
  }).done(function (data) {
    let ajaxContent = $(data).find('#middleRightWrapper');
    $wrapperElement.replaceWith(ajaxContent);
    $wrapperElement.removeClass('isLoading');
  });
}