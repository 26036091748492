$.extend(app, {
  advancePaymentMonth: {
    form: null,
  },
});

app.advancePaymentMonth.form = (function () {
  const config = {
    prototypeName: null,
    uniqid: null,
    messages: {
      loadingFailed: null,
    },
    routes: {
      form: null,
      fields: null,
    },
    selectors: {
      fields: null,
      form: null,
      horizontalRule: null,
      item: null,
      loadingIndicator: null,
      switch: null,
      button: {
        add: null,
        remove: null,
      },
    },
  };
  let jqXHR = null;
  let itemIndex;

  return {
    config: config,
    init: function () {
      $(window).on('popstate', function (event) {
        disableFormFields();
        window.location.reload(true);
      });

      $(config.selectors.switch).on('change', function () {
        load();
      });

      itemIndex = getItemsCount();
      $(config.selectors.button.add).on('click', function () {
        add();
      });

      $(config.selectors.button.remove).on('click', function () {
        remove($(this));
      });

      updateHorizontalRuleVisibility();
      highlightRowByAnchor();
    },
  };

  function load() {

    disableFormFields();
    showLoadingIndicator();

    if (jqXHR !== null && jqXHR.readyState !== 4) {
      jqXHR.abort();
    }

    jqXHR = $.ajax({
      url: Routing.generate(config.routes.fields, {
        date: $(config.selectors.switch).val(),
        uniqid: config.uniqid,
      }),
    })
    .done(function (response) {
      let url = Routing.generate(config.routes.form, {
        date: $(config.selectors.switch).val(),
        uniqid: config.uniqid,
      });

      $(config.selectors.form).attr('action', url);
      $(config.selectors.fields).replaceWith(response.fields);
      itemIndex = getItemsCount();

      setWindowUrl(url);
      initializationDatepicker();
      updateHorizontalRuleVisibility();
    })
    .fail(function () {
      alert(config.messages.loadingFailed);
      window.location.reload(true);
    })
    .always(function () {
      hideLoadingIndicator();
    });

    function showLoadingIndicator() {
      $(config.selectors.loadingIndicator).removeClass('invisible');
    }

    function hideLoadingIndicator() {
      $(config.selectors.loadingIndicator).addClass('invisible');
    }
  }

  function disableFormFields() {
    $(config.selectors.fields).find('input, radio, select').each(function () {
      $(this).attr('disabled', 'disabled');
    });
  }

  function add() {
    let $item = $(decodeURIComponent($(config.selectors.fields).data('prototype')).replace(
      new RegExp(config.prototypeName, 'g'),
      itemIndex.toString()
    ));

    $item.find(config.selectors.button.remove).on('click', function () {
      remove($(this));
    });

    $(config.selectors.fields).append($item);
    itemIndex++;

    initializationDatepicker();
    updateHorizontalRuleVisibility();
  }

  function remove($trigger) {
    $trigger.closest(config.selectors.item).remove();
    updateHorizontalRuleVisibility();
  }

  function getItemsCount() {
    return $(config.selectors.fields).find(config.selectors.item).length;
  }

  function updateHorizontalRuleVisibility() {
    if ($(config.selectors.fields).find(config.selectors.item).length === 0) {
      $(config.selectors.horizontalRule).addClass('hide');
    } else {
      $(config.selectors.horizontalRule).removeClass('hide');
    }
  }

})();
