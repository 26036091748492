$.extend(app, { person: null });

app.person = (function () {
  let config = {
    formsForDeleteSelector: null,
    requestErrorMessage: null,
  };
  return {
    config: config,
    standardHourRate: standardHourRate(),
    extraHourRate: extraHourRate(),
  };

  function standardHourRate() {
    let config = {
      contentSelector: null,
    };
    return {
      config: config,
      initForm: initForm,
      initTable: initTable,
    };

    function initForm() {
      initializationDatepicker();
    }

    function initTable() {
      initFormsForDelete(
          $(config.contentSelector)
      );
    }
  }

  function extraHourRate() {
    let config = {
      contentSelector: null,
    };
    return {
      config: config,
      initForm: initForm,
      initTable: initTable,
    };

    function initForm() {
      initializationDatepicker();
    }

    function initTable() {
      initFormsForDelete(
        $(config.contentSelector)
      );
    }
  }

  function initFormsForDelete($content) {
    $content.find(config.formsForDeleteSelector).each(function () {
      let $this = $(this);
      $this.on('submit', function () {
        if (!window.confirm($this.data('require-confirm'))) {
          return false;
        }

        $.ajax({
          url: $this.attr('action'),
          data: $this.serialize(),
          type: 'POST',
          success: function () {
            $.magnificPopup.instance.updateItemHTML();
          },

          error: function () {
            $content.html(config.requestErrorMessage);
          },
        });
        return false;
      });
    });
  }
})();
