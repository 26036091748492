$.extend(app, {cost_month: null});

app.cost_month = (function () {
    let config = {
        $ajaxFailMessage: null,
        $ajaxLoader: null,
        $costButtonAdd: null,
        $costPrototype: null,
        $costsContainer: null,
        $costBlockAddPerson: null,
        $costBlockAllPersonLocked: null,
        $fieldMonth: null,
        $form: null,
        costButtonRemoveSelector: null,
        costSelector: null,
        uniqid: null,
        urlLoadCostFields: null,
        urlPattern: {
            form: null,
            window: null,
        },
    };
    let costsLength = null;
    let loadRequest = null;

    return {
        config: config,
        init: init,
    };

    function init() {
        initCostsContainer();
        config.$fieldMonth.on('change', loadCostFields);
        config.$costButtonAdd.on('click', addCost);
        highlightRowByAnchor();
        disableWorkers();
    }

    function initCostsContainer() {
        costsLength = config.$costsContainer.find(config.costSelector).length;
        config.$costsContainer.find(config.costButtonRemoveSelector).on('click', removeCost);
    }

    function disableWorkers() {
        let disabledWorkers = $('input[type="hidden"][data-type="disabled_workers"]');
        if(disabledWorkers.length == 1) {
            let selects = $("select[data-type='cost_workers']");
            var disabledWorkersValue = JSON.parse(disabledWorkers.val());
                $.each(selects, function(key, select) {
                let $select = $(select);
                $.each($select.find('option'), function(key, option) {
                    let $option = $(option);
                    if ($option.val() != "" && disabledWorkersValue.indexOf(parseInt($option.val())) > -1) {
                        $option.attr('disabled', 'disabled');
                    } else {
                        $option.removeAttr('disabled');
                    }
                });
            });
        }
    }

    function loadCostFields() {
        if (loadRequest != null && loadRequest.readyState != 4) {
            loadRequest.abort();
        }
        loadRequest = $.ajax({
            url: config.urlLoadCostFields,
            method: 'post',
            data: {
                uniqid: config.uniqid,
                month: config.$fieldMonth.val()
            },
            beforeSend: function () {
                config.$ajaxLoader.removeClass('invisible');
                config.$ajaxFailMessage.addClass('hide');
            },
        }).done(function (data) {
            config.$costsContainer.html(data.form_cost_fields);
            initCostsContainer();
            updateFormUrl();
            updateWindowUrl();
            config.$ajaxLoader.addClass('invisible');
            if (data.hasPersonWithoutLockedPayment == false) {
                config.$costBlockAddPerson.addClass('hide');
                config.$costBlockAllPersonLocked.removeClass('hide');
            } else {
                config.$costBlockAddPerson.removeClass('hide');
                config.$costBlockAllPersonLocked.addClass('hide');
            }
            if (data.disabledPersons) {
                $('input[type="hidden"][data-type="disabled_workers"]').val(data.disabledPersons);
            }
            disableWorkers();
            toggleHrVisibility();
        }).fail(function () {
            config.$costsContainer.html('');
            config.$ajaxLoader.addClass('invisible');
            config.$ajaxFailMessage.removeClass('hide');
        });
    }

    function updateFormUrl() {
        let url = createUrl(config.urlPattern.form);
        config.$form.attr('action', url);
    }

    function updateWindowUrl() {
        if (typeof window.history.pushState !== 'function') {
            return;
        }
        let url = createUrl(config.urlPattern.window);
        window.history.pushState(url, window.title, url);
    }

    function createUrl(prototype) {
        let month = config.$fieldMonth.val();
        return prototype.replace('00/0000', month);
    }

    function addCost() {
        let costPrototypeHtml = config.$costPrototype.html();
        config.$costsContainer.append($(costPrototypeHtml.replace(/__name__/g, costsLength)));
        initCostsContainer();
        toggleHrVisibility();
    }

    function removeCost() {
        $(this).parents(config.costSelector).first().remove();
        toggleHrVisibility();
    }

    function toggleHrVisibility() {
        const costWorkerRow = document.querySelectorAll('[data-control="cost-fields"]');
        const hr = document.querySelector('.horizontalRule');
        if (costWorkerRow && costWorkerRow.length > 1) {
            hr.classList.remove("hide");
        }
        else {
            hr.classList.add("hide");
        }
    }
})();
