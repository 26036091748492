$.extend(app, {attendance: null});

app.attendance = (function () {
    let config = {
        allocation: {
            $controls: null,
            idsOfAlreadyAllocatedPeople: [],
            form: {
                prototype: null,
                name: null,
                statesCount: null
            },
        },
        confirmButton: {
            selector: null,
        },
        form: {
            urlPrototype: null,
            selector: null,
        },
        urlPrototype: null,
        states: {},
        penalties: {}
    };

    return {
        config: config,
        init: init
    };

    function init() {
        $('#job_order_workers_workStartDate').on('change', loadFormItems());
        $('[data-selector="show-confirmation-light-box"]').on('click', showConfirmationLightBox());
        initBatchActions();
        initFormItems();
        initAllocation();
        initStickyHeader();
        highlightRowByAnchor();
    }

    function initBatchActions() {
        $('[data-click-to-state]').on('click', setAllWorkersSameState);
        $('[data-click-uptime], [data-click-downtime]').on('click', function() {
            setAllWorkersSameHours($(this).data('target'));
        });

        function setAllWorkersSameState() {
            let val = $('[data-state-to-all="0"] input:checked').val();
            $('[data-state-to-all] input').removeAttr('checked');
            $('[data-state-to-all] input').prop('checked', false);
            $('[data-state-to-all] input[value="' + val + '"]').each(function() {
                $(this).prop('checked', true);
                $(this).attr('checked', 'checked');
                $(this).trigger('click');
            });
        }

        function setAllWorkersSameHours(target) {
            let val = $('[data-'+ target +'-to-all="0"]').val();
            $('[data-'+ target +'-to-all]').val(val);
        }
    }

    function initFormItems() {
        $('[data-radio] input').on('click', changeSelectWithRadio);
        $('[data-select]').on('change', changeRadioWithSelect);

        $('#attendance-form-fields').find('[data-attendance-id]').each(function () {
            let $attendance = $(this);
            updateRowColorHighlight($attendance);
            $attendance.find('select').each(function () {
                $(this).on('change', function () {
                    let state = $(this).val();
                    updatePenaltyAccordingToState($attendance, state);
                    updateTimeAccordingToState($attendance, state);
                    updateRowColorHighlight($attendance);
                });
            });
            $attendance.find('input[type=radio]').each(function () {
                $(this).on('click', function () {
                    let state = $(this).val();
                    updatePenaltyAccordingToState($attendance, state);
                    updateTimeAccordingToState($attendance, state);
                    updateRowColorHighlight($attendance);
                });
            });
            $attendance.find('[data-attendance-downtime]').each(function () {
              $(this).on('change', function () {
                updateRowColorHighlight($attendance);
              });
            });
        });

        function changeSelectWithRadio(){
            let val = $(this).val();
            let closest = $(this).closest(".participantItem");
            closest.find('[data-select]').val(val);
        }

        function changeRadioWithSelect() {
            let val = $(this).val();
            let closest = $(this).closest(".participantItem");
            closest.find('[data-radio] input').prop('checked', false);
            closest.find('input[value="' + val + '"]').prop('checked',true);
        }

        function updatePenaltyAccordingToState($attendance, state) {
            let penalty = config.penalties.none;
            if (state == config.states.sick) {
                penalty = config.penalties.sick;
            }
            $attendance.find('[data-selector=penalty-value]').val(penalty.value);
            $attendance.find('[data-selector=penalty-unit]').val(penalty.unit);
            $attendance.find('[data-selector=note]').val(penalty.note);
        }

        function updateTimeAccordingToState($attendance, state) {
            if (state != config.states.present) {
                $attendance.find('[data-uptime-to-all]').val(0);
                $attendance.find('[data-downtime-to-all]').val(0);
            }
        }

        function updateRowColorHighlight ($attendance) {
          const $downTime = $attendance.find('[data-attendance-downtime]');
          removeRowColorHighlight($attendance);
          if ($downTime.val() > 0 || $downTime.val() < 0) {
            $attendance.addClass('highlightRowBlue');
          } else {
            updateColorAccordingToState($attendance, $attendance.find('select').val())
          }
        }

        function removeRowColorHighlight ($attendance) {
          $attendance.removeClass('highlightRowGreen highlightRowYellow highlightRowRed highlightRowBlue');
        }

        function updateColorAccordingToState($attendance, state) {
            switch (parseInt(state)) {
                case config.states.otherBuilding:
                    $attendance.addClass('highlightRowGreen');
                    break;
                case config.states.sick:
                    $attendance.addClass('highlightRowYellow');
                    break;
                case config.states.vacation:
                    $attendance.addClass('highlightRowRed');
                    break;
            }
        }
    }

    function initStickyHeader() {
        let eToStick = $('#sticker')[0];
        let eHeight = eToStick.offsetHeight;
        let inview = new Waypoint.Inview({
            element: eToStick,
            enter: function(direction) {
                initStickerPosition(this.element, true, "0")
            },
            entered: function(direction) {
                initStickerPosition(this.element, true, "0")
            },
            exit: function(direction) {
                initStickerPosition(this.element, false, eHeight)
            },
            exited: function(direction) {
                initStickerPosition(this.element, false, eHeight)
            },
            context: document.querySelector('#content > article')
        });

        window.addEventListener("orientationchange", function() {
            Waypoint.refreshAll();
        });

        window.addEventListener("resize", function() {
            Waypoint.refreshAll();
        });

        function initStickerPosition(el, entered, mgTop) {
            const style = window.getComputedStyle(el);
            if(entered) {
                el.classList.remove('isSticked');
                el.parentNode.setAttribute("style", "margin-top: " + mgTop + "px");
            } else {
                if (style.display !== 'none') {
                    el.classList.add('isSticked');
                    el.parentNode.setAttribute("style", "margin-top: " + mgTop + "px");
                }
            }
        }
    }

    function initAllocation() {
        const $button = config.allocation.$controls.find('button');
        const $select = config.allocation.$controls.find('select');

        updateDisabledOptionsInSelect();
        setDefaultStateToControls();

        $button.on('click', function () {
            addAttendanceForm();
            updateDisabledOptionsInSelect();
            hideMessageForEmptyData();
            setDefaultStateToControls();
            initFormItems();
            enableSubmitting();
        });
        $select.on('change', function () {
            if ($select.val() !== '') {
                $button.removeAttr('disabled');
            } else {
                $button.attr('disabled', 'disabled');
            }
        });

        function addAttendanceForm() {
            const $selected = $select.find('[value="'+$select.val()+'"]');

            let form = decodeURIComponent(config.allocation.form.prototype);

            form = form.replace(/__name__/g, config.allocation.form.name);

            form = form.replace(/__full_name__/g, $selected.text());

            form = form.replace(/__internal_number__/g, $selected.attr('data-internal-number'));

            form = form.replace(/__hour_rate__/g, $selected.data('hourRate'));

            form = form.replace(/__loop_index__/g, config.allocation.form.name + 1);

            let i = 0;
            while (form.indexOf('__state_id_'+i+'__') !== -1) {
                form = form.replace(
                    new RegExp('__state_id_'+i+'__', 'g'),
                    config.allocation.form.name * config.allocation.form.statesCount + i
                );
                i++;
            }

            $('#attendance-form-fields').append(form);
            $('#attendance-form-fields').find('[data-selector="field-person-id"]').last().val(
                $select.val()
            );

            $('[data-selector="remove-person-attendance"]').on('click', function () {
                $(this).parents('[data-attendance-id]').remove();
                updateDisabledOptionsInSelect();
            });

            config.allocation.form.name++;
        }

        function setDefaultStateToControls() {
            $button.attr('disabled', 'disabled');
            $select.val('');
        }
    }

    function updateAllocation(data) {
        const $select = config.allocation.$controls.find('select');

        config.allocation.form.name = data.allocation.form.name;
        config.allocation.idsOfAlreadyAllocatedPeople = data.allocation.idsOfAlreadyAllocatedPeople;

        if (data.isJobOrderAttendanceEditable) {
            $select.removeAttr('disabled');
        } else {
            $select.attr('disabled', 'disabled');
        }

        $select.find('[data-internal-number]').remove();

        let person;
        for (let i = 0; i < data.allocation.availablePeople.length; i++) {
            person = data.allocation.availablePeople[i];
            $select.append('<option value="'+person.id+'" data-internal-number="'+person.internalNumber+'" data-hour-rate="'+person.hourRate+'">'+person.fullName+'</option>');
        }

        updateDisabledOptionsInSelect();
    }

    function updateDisabledOptionsInSelect() {
        let idsOfAlreadyAllocatedPeople = config.allocation.idsOfAlreadyAllocatedPeople;
        if (typeof idsOfAlreadyAllocatedPeople === 'string') {
            idsOfAlreadyAllocatedPeople = JSON.parse(idsOfAlreadyAllocatedPeople);
        }
        $('[data-selector="field-person-id"]').each(function () {
            idsOfAlreadyAllocatedPeople.push(parseInt($(this).val()));
        });
        config.allocation.$controls.find('select option').each(function () {
            $(this).removeAttr('disabled');
            if (idsOfAlreadyAllocatedPeople.indexOf(parseInt($(this).val())) !== -1) {
                $(this).attr('disabled', 'disabled');
            }
        });
    }

    function loadFormItems() {
        let jqXHR = null;

        return function () {
            $('[data-loading="loading"]').removeClass('invisible');
            $("[data-warning='warning-text-error']").addClass('hide');
            $('#attendance-form-fields input').attr('disabled', 'disabled');
            $('#attendance-form-fields select').attr('disabled', 'disabled');

            if (jqXHR != null && jqXHR.readyState != 4) {
                jqXHR.abort();
            }

            jqXHR = $.ajax({
                url: createUrl(config.form.urlPrototype),
                method: 'post',
            }).done(function (data) {
                updateForm(data);
                updateAllocation(data);
                updateWindowUrl();
                initFormItems();
                $('[data-loading="loading"]').addClass('invisible');
            }).fail(function() {
                $('#attendance-form-fields > .row').remove();
                $("[data-warning='warning-text-error']").removeClass('hide');
                $('[data-loading="loading"]').addClass('invisible');
                $("[data-warning='warning-text-empty-result']").addClass('hide');

            });
        };

        function updateForm(data) {
            $(config.form.selector).attr('action', createUrl(config.form.urlPrototype));
            $('#attendance-form-fields').html(data.formFields);

            enableBulkFilling();
            enableSubmitting();
            hideMessageForEmptyData();
            replaceSickNote(data);

            if (! data.isAllPeopleAttendancesEditable || ! data.isJobOrderAttendanceEditable) {
                disableBulkFilling();
            }

            if (data.isEmpty) {
                disableBulkFilling();
                disableSubmitting();
                showMessageForEmptyData();
            }

            function enableBulkFilling() {
                $('[data-click-to-state="0"]').removeAttr("disabled");
                $('[data-target="uptime"]').removeAttr("disabled");
                $('[data-target="downtime"]').removeAttr("disabled");
            }

            function disableBulkFilling() {
                $('[data-click-to-state="0"]').attr('disabled', 'disabled');
                $('[data-target="uptime"]').attr('disabled', 'disabled');
                $('[data-target="downtime"]').attr('disabled', 'disabled');
            }

            function disableSubmitting() {
                $('[data-action="send-form"]').attr('disabled', 'disabled');
            }

            function showMessageForEmptyData() {
                $("[data-warning='warning-text-empty-result']").removeClass('hide');
            }

            function replaceSickNote(data) {
                config.penalties.sick.note = data.sickNote;
            }
        }

        function updateWindowUrl() {
            if (typeof window.history.pushState == 'function') {
                let url = createUrl(config.urlPrototype);
                window.history.pushState(url, window.title, url);
            }
        }

        function createUrl(prototype) {
            let day = $('#job_order_workers_workStartDate').val().replace(/\./g, '-');
            return prototype.replace('00-00-0000', day);
        }
    }

    function showConfirmationLightBox() {
        let jqXHR = null;
        let $form = $(config.form.selector);

        return function () {
            if (jqXHR != null && jqXHR.readyState != 4) {
                jqXHR.abort();
            }

            jqXHR = $.ajax({
                url: $form.attr('action'),
                method: 'post',
                data: $form.serialize()
            }).done(function (data) {
                if (! data.is_valid) {
                    $form.find('[data-after-submit="leave"]').click();
                    return;
                }
                $.magnificPopup.open({
                    items: {
                        src: data.content,
                        type: 'inline'
                    },
                    callbacks: {
                        open: function() {
                            $(config.confirmButton.selector).on('click', function () {
                                $form.find('[data-after-submit="leave"]').click();
                            });
                        }
                    }
                });
            });
        };
    }

    function enableSubmitting() {
        $('[data-action="send-form"]').removeAttr('disabled');
    }

    function hideMessageForEmptyData() {
        $('[data-warning="warning-text-empty-result"]').addClass('hide');
    }
})();