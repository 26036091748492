$.extend(app, {
  joborder: {
    detail: null,
    form: null,
  },
});

app.joborder.detail = (function () {
  return {
    init: function () {
      let $attendanceTableWrapper = $('#monthlyAttendanceTableWrapper');
      let $attendanceSelect = $('#attendanceMonthlyMonth');
      let attendanceRoute = 'admin_app_joborder_render_monthly_attendance_table';
      let attendanceRouteExport = 'admin_app_joborder_export_monthly_attendance_table_excel';
      let $constructionRecordsTableWrapper = $('#buildingJournalTableWrapper');
      let $constructionRecordsSelect = $('#constructionRecordsSelect');
      let constructionRecordsRoute = 'admin_app_joborder_render_monthly_construction_records_table';
      let constructionRecordsRouteExport = 'admin_app_joborder_export_monthly_construction_records_table_excel';

      if ($attendanceSelect.length !== 0) {
        $attendanceSelect.change(function () {
          renderMonthlyTable(
            $attendanceTableWrapper,
            $attendanceSelect,
            attendanceRoute,
            attendanceRouteExport
          );
        });
      }

      if ($attendanceTableWrapper.length !== 0) {
        renderMonthlyTable(
          $attendanceTableWrapper,
          $attendanceSelect,
          attendanceRoute,
          attendanceRouteExport
        );
      }

      if ($constructionRecordsSelect.length !== 0) {
        $constructionRecordsSelect.change(function () {
          renderMonthlyTable(
            $constructionRecordsTableWrapper,
            $constructionRecordsSelect,
            constructionRecordsRoute,
            constructionRecordsRouteExport
          );
        });
      }

      if ($constructionRecordsTableWrapper.length !== 0) {
        renderMonthlyTable(
          $constructionRecordsTableWrapper,
          $constructionRecordsSelect,
          constructionRecordsRoute,
          constructionRecordsRouteExport
        );
      }
    },
  };

  /**
   * @param $tableWrapper
   * @param $monthSelect
   */
  function renderMonthlyTable($tableWrapper, $monthSelect, route, routeExport) {
    let jobOrder = $tableWrapper.data('job-order-id');
    let selectValue = $monthSelect.val();
    $tableWrapper.addClass('isLoading');

    $.ajax({
      method: 'GET',
      url: Routing.generate(route, { id: jobOrder, date: selectValue }),
    }).done(data => {
      $tableWrapper.html(data).removeClass('isLoading');
      $(document).foundation();
    }).fail(() => {
      $tableWrapper.find('.error').style('display: block').removeClass('isLoading');
    });
    if (typeof routeExport !== 'undefined' && routeExport !== '') {
      updateExcelExportLink(selectValue, jobOrder, routeExport);
    }
  }

  /**
   * @param selectValue
   * @param jobOrder
   */
  function updateExcelExportLink(selectValue, jobOrder, routeExport) {
    let $excelExportLink = $("a[data-type='" + routeExport + "']");
    if ($excelExportLink.length === 1) {
      let newLinkValue = Routing.generate(routeExport, { id: jobOrder, date: selectValue });
      if (newLinkValue.length > 0) {
        $excelExportLink.removeClass('hide').attr('href', newLinkValue);
      }
    }
  }

})();

app.joborder.form = (function () {
  return {
    init: function () {
      initUserMultiForm('leadassembler');
      initUserMultiForm('manager');
      chooseColor();
    },
  };

  function initUserMultiForm(dataWrapper) {
    let $wrapper = $('[data-wrapper="' + dataWrapper + '"]');
    let $addButton = $wrapper.find('button[data-type="add"]');
    let selectClass = '.select' + dataWrapper;
    let rowLength = $wrapper.find('.row').length;
    let counter = (rowLength > 0) ? rowLength : 0;

    if ($addButton) {
      $addButton.on('click', function () {
        cloneSelect();
      });
    }

    retagSelect(selectClass);
    bindDeletionSelect();

    function cloneSelect() {
      let newElement = $('#prototype' + dataWrapper).find('.row').clone();

      newElement.find('select').each(function () {
        this.name = this.name.replace('[__name__]', '[' + counter + ']');
        this.id = this.id.replace('__name__', counter);
        this.addEventListener('change', function () {
          const optionValue = $(this).val();
          $(this).find('option[value="' + optionValue + '"]').attr('selected', 'selected');
          retagSelect(selectClass);
        });
      });

      newElement.insertBefore($addButton);
      retagSelect(selectClass);
      counter++;
      bindDeletionSelect();
    }

    function bindDeletionSelect() {
      let $deleteButton = $wrapper.find('button[data-type="delete"]');

      if ($deleteButton) {
        $deleteButton.on('click', function () {
          $(this).closest('.row').remove();
          retagSelect(selectClass);
        });
      }
    }

  }

})();
