$.extend(app, {
  dailyConstructionRecord: {
    form: null,
  },
});

app.dailyConstructionRecord.form = (function () {
  const config = {
    routeName: null,
    uniqid: null,
    url: null,
    message: {
      loadingFailed: null,
    },
    selector: {
      day: null,
      form: null,
      jobOrder: null,
      loading: null,
      downtimeReason: null,
    },
  };
  let jqXHR = null;

  return {
    config: config,
    init: init,
  };

  function init() {
    $(window).on('popstate', function (event) {
      disableFormFields();
      window.location.reload();
    });

    $(config.selector.day).on('change', function () {
      load();
    });

    $(config.selector.jobOrder).on('change', function () {
      load();
    });

    $(config.selector.downtimeReason).on('change', function () {
      load();
    });

    initDowntimeWysiwyg();
  }

  function load() {
    let url = Routing.generate(config.routeName, {
      jobOrderId: $(config.selector.jobOrder).val(),
      date: $(config.selector.day).val().replace(/\./g, '-'),
    });

    showLoading();
    disableFormFields();

    if (jqXHR !== null && jqXHR.readyState !== 4) {
      jqXHR.abort();
    }

    jqXHR = $.ajax({
      url: url,
    })
    .done(function (response) {
      $(config.selector.form).attr(
        'action',
        Routing.generate('admin_app_dailyconstructionrecord_edit_by', {
          jobOrderId: $(config.selector.jobOrder).val(),
          date: $(config.selector.day).val().replace(/\./g, '-'),
          uniqid: config.uniqid,
        })
      );

      let recordId = $(config.selector.record).attr('id');
      let downtimeReasonId = $(config.selector.downtimeReason).attr('id');

      $(config.selector.extraWork).prop('checked', response.extraWork);
      $(config.selector.downtime).prop('checked', response.downtime);

      initDowntimeWysiwyg();

      tinymce.get(recordId).setContent(response.record);
      tinymce.get(downtimeReasonId).setContent(response.downtimeReason);

      tinyMCE.remove();
      initTinyMCE();

      setWindowUrl(url);
    })
    .fail(function () {
      alert(config.message.loadingFailed);
      window.location.reload(true);
    })
    .always(function () {
      enableFormFields();
      hideLoading();
    });

    function showLoading() {
      $(config.selector.loading).removeClass('invisible');
    }

    function hideLoading() {
      $(config.selector.loading).addClass('invisible');
    }
  }

  function disableFormFields() {
    $(config.selector.jobOrder).attr('disabled', 'disabled');
    $(config.selector.day).attr('disabled', 'disabled');
    tinymce.activeEditor.getBody().setAttribute('contenteditable', false);
  }

  function enableFormFields() {
    $(config.selector.jobOrder).removeAttr('disabled');
    $(config.selector.day).removeAttr('disabled');
    tinymce.activeEditor.getBody().setAttribute('contenteditable', true);
  }

  function initDowntimeWysiwyg() {
    let $downtimeOptionCheckbox = $('input[type=checkbox][name*=downTime]');
    let $affectedElement = $('[data-option-result-downtime]');

    $($downtimeOptionCheckbox).on('click', function () {
      $downtimeOptionCheckbox.is(':checked') ? $affectedElement.removeClass('hide') : $affectedElement.addClass('hide');
    });

    if ($downtimeOptionCheckbox.is(':checked')) {
      $affectedElement.removeClass('hide');
    } else {
      $affectedElement.addClass('hide');
    }
  }

})();
